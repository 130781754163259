.signature {
    display: block;

    &__pad {
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 25px;
    }
}
