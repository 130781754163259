.photo {
    ion-button {
        --padding-start: 23px;
        --padding-end: 23px;
    }

    &__img {
        width: 150px;
        height: auto;
    }
}
