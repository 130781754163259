.content {
    text-decoration: none;
    width: 100%;

    ion-row + ion-row {
        border-top: 1px solid #5d5d5d;
    }

    &__title {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        display: flex;

        ion-badge {
            margin-left: 5px;
        }
    }

    &__description {
        font-size: 12px;
        margin: 0;
    }
}
