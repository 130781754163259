.footer {
  padding: 0px 30px;
  max-height: 60px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  text-align: left;

  &__version {
    float: right;
  }
}
