.input-item {
    width: 100%;
    margin-bottom: 20px;

    &--disabled {
        ion-label,
        ion-icon {
            opacity: .3;
        }
    }

    ion-input,
    ion-select,
    ion-textarea {
        background: white;
        border: 1px solid #808080;
        border-radius: 5px;
    }

    ion-input,
    ion-textarea {
        padding: 8px 12px !important;
    }

    ion-select {
        padding: 16px 12px !important;
    }

    ion-item {
        --highlight-background: none;
        --border-color: transparent;
    }

    ion-radio-group {
        ion-label {
            padding-left: 15px;
        }

        ion-item {
            --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
            padding-right: 17px;
        }
    }

    ion-label {
        transform: none !important;
        font-weight: 600;
        margin-bottom: 5px !important;
        white-space: normal !important;
        overflow: visible !important;
    }

    ion-icon {
        margin-right: 0 !important;
    }

    ion-radio {
        --color-checked: var(--ion-color-success);
    }

    .text-label {
        margin-left: 15px;
    }
}

.invalid {
    color: red !important;

    ion-label {
        --color: red !important;
    }
}


