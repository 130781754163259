.header {
  height: 148px;
  background-color: #fff;

  &__left-side {
    margin-left: 30px;
  }

  &__doctor-name {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  &__seal-number {
    display: block;
    font-size: 12px;
    margin: 0;
  }

  &__event-details {
    float: right;
    text-align: right;
    margin-top: 33px;

    h2 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 12px;
      margin: 0;
    }
  }
}
