.alert {
    position: relative;
    padding: 13px 19px;
    margin-bottom: 15px;
    border-radius: var(--app-border-radius);
    box-shadow: var(--app-box-shadow);

    &--danger {
        background-color: var(--ion-color-danger);
        color: #ffffff;
    }

    &--warning {
        background-color: var(--ion-color-warning);
        color: var(--ion-text-color);
    }

    &--success {
        background-color: var(--ion-color-success);
        color: var(--ion-text-color);
    }

    &--cursor-pointer {
        cursor: pointer;
    }

    &__close-icon {
        font-size: 30px;
        position: absolute;
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
