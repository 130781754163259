.login {
    text-align: center;

    &__logo {
        margin-top: 90px;
        margin-bottom: 20px;
    }

    &__title {
        color: #014489;
        font-size: 36px;
        font-weight: bold;
        margin: 0 0 50px;
    }

    &__input {
        width: 460px;
        margin: 0 auto 20px;

        &--margin-top {
            margin-top: 45px;
        }

        ion-item {
            width: 100%;
        }

        ion-button {
            width: 100%;
        }
    }
}
