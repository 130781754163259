.control-buttons {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-column-gap: 30px;
    justify-content: end;

    &--three-column {
        grid-template-columns: 150px 200px 250px;
    }

    ion-button {
        --padding-start: 50px;
        --padding-end: 50px;
    }
}
