.button-group {
    display: grid;
    grid-row-gap: 30px;
    justify-content: start;

    a {
        color: white;
        text-decoration: none ;
    }
}
