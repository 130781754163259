.ion-list {
    margin-bottom: 50px;
    padding: 0 5px;

    ion-item {
        --ion-item-background: #fff;
        margin: 0 0 15px 0;
        text-decoration: none;
        background: white;
        padding: 10px 0;
        border-radius: var(--app-border-radius);
        box-shadow: var(--app-box-shadow);
    }
}

.add-button {
    position: fixed;
    --ion-safe-area-right: 20px;
    bottom: 20px;

    ion-icon {
        --ion-color-success: #1B9B57;
        font-size: 36px;
    }
}
